var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.$apollo.loading
    ? _c(
        "div",
        {
          staticClass:
            "group-switcher-wrapper position-relative d-flex pl-3 pr-2 mr-3"
        },
        [
          _c("b-icon", {
            staticClass: "mr-2 align-self-center",
            attrs: { icon: "layers-fill", "aria-hidden": "true" }
          }),
          _c("treeselect", {
            attrs: {
              multiple: false,
              clearable: false,
              options: _vm.allGroupsOfLoggedInUser,
              disabled: _vm.disabled
            },
            on: { select: _vm.changeGroup },
            model: {
              value: _vm.currentGroupId,
              callback: function($$v) {
                _vm.currentGroupId = $$v
              },
              expression: "currentGroupId"
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }