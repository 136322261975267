import gql from 'graphql-tag';

export default gql`
query getAllGroupsOfCurrentUser($uid: Int!) {
  users_roles_groups(where: {user_id: {_eq: $uid}}, distinct_on: group_id) {
    user_id
    group {
      group_id
      name
      root_folder_id
      slug
      domain
      groups_privileges {
        value
        privilege {
          handle
        }
      }
    }
  }
  users_roles_teams(where: {user_id: {_eq: $uid}}) {
    team {
      teams_items(where: {}) {
        folder_id
        folder {
          group_id
          parent_folder_id
          group {
            name
            slug
            domain
            root_folder_id
            groups_privileges {
              value
              privilege {
                handle
              }
            }
          }
          lookup_entries { slug_path }
        }
        project_id
        project {
          group_id
          group {
            name
            slug
            domain
            root_folder_id
            groups_privileges {
              value
              privilege {
                handle
              }
            }
          }
          lookup_entries { slug_path }
        }
      }
    }
  }
}
`;
