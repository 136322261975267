import gql from 'graphql-tag';

export default gql`
query getAllGroups {
  groups {
    group_id
    name
    root_folder_id
    slug
    domain
    group_path
    parent_group_id
    settings
    pirsch_code
  }
}
`;
