<template>
  <div
    v-if="!$apollo.loading"
    class="group-switcher-wrapper position-relative d-flex pl-3 pr-2 mr-3"
  >
    <b-icon icon="layers-fill" aria-hidden="true" class="mr-2 align-self-center"></b-icon>
    <treeselect
      v-model="currentGroupId"
      :multiple="false"
      :clearable="false"
      :options="allGroupsOfLoggedInUser"
      @select="changeGroup"
      :disabled="disabled"
    />
  </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect';
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css';

import UpdateUserLastGroupId from '@/graphQlQueries/mutations/updateUserLastGroupId';
import GetAllGroups from '@/graphQlQueries/queries/getAllGroups';
import GetAllGroupsOfCurrentUser from '@/graphQlQueries/queries/getAllGroupsOfCurrentUser';

export default {
  name: 'HeaderGroupDropdown',
  components: { Treeselect },
  data: () => ({
    allGroupsOfLoggedInUser: [],
    showGroupSelectWindow: false,
    currentGroupId: null,
    disabled: true,
  }),
  props: {
    userId: { type: Number },
    isSysAdmin: { type: Boolean },
    groupId: { type: Number },
  },
  created() {
    this.currentGroupId = this.groupId;
  },
  apollo: {
    allGroupsOfLoggedInUser: {
      variables() {
        return (!this.isSysAdmin)
          ? { uid: this.userId }
          : null;
      },
      query() {
        return (!this.isSysAdmin)
          ? GetAllGroupsOfCurrentUser
          : GetAllGroups;
      },
      update(data) {
        const groupObjects = [];
        if (this.isSysAdmin) {
          data.groups.forEach((groupItem) => {
            const element = {
              id: groupItem.group_id,
              name: groupItem.name,
              label: groupItem.name,
            };
            if (this.currentGroupId === groupItem.group_id) {
              element.isDisabled = true;
            }
            if (!groupObjects.find((elem) => elem.id === element.id)) {
              groupObjects.push(element);
            }
          });
        } else {
          data.users_roles_groups.forEach((groupItem) => {
            const element = {
              id: groupItem.group.group_id,
              name: groupItem.group.name,
              label: groupItem.group.name,
            };
            if (this.currentGroupId === groupItem.group.group_id) {
              element.isDisabled = true;
            }
            if (!groupObjects.find((elem) => elem.id === element.id)) {
              groupObjects.push(element);
            }
          });
          data.users_roles_teams.forEach((team) => {
            // get all groups where items are insight
            team.team.teams_items.forEach((teamItem) => {
              let itemGroupId = '';
              let groupName = '';
              if (teamItem.folder) {
                itemGroupId = teamItem.folder.group_id;
                groupName = teamItem.folder.group.name;
              }
              if (teamItem.project) {
                itemGroupId = teamItem.project.group_id;
                groupName = teamItem.project.group.name;
              }
              const element = {
                id: itemGroupId,
                name: groupName,
                label: groupName,
              };
              if (this.currentGroupId === itemGroupId) {
                element.isDisabled = true;
              }
              if (!groupObjects.find((elem) => elem.id === element.id)) {
                groupObjects.push(element);
              }
            });
          });
        }
        if (groupObjects.length > 1) this.disabled = false;
        // display the group switcher only if there are more than one group
        this.showGroupSelectWindow = groupObjects.length > 1;

        groupObjects.sort((a, b) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        if (groupObjects.length === 1) {
          this.$emit('only-one-group-exists');
        }
        return groupObjects;
      },
      skip() {
        return !this.userId;
      },
      fetchPolicy: 'network-only',
    },
  },
  methods: {
    async changeGroup(selectedGroup) {
      // set the selected group id in the last group id field of the user
      await this.$apollo.mutate({
        mutation: UpdateUserLastGroupId,
        variables: {
          uid: this.userId,
          lastGroupId: selectedGroup.id,
        },
      });
      this.$nextTick(() => {
        // if you change the group always go to the root
        if (this.$route.path !== '/') {
          this.$router.push('/').catch((err) => {
            throw new Error(`Routing Error occurred! ${err}.`);
          });
        } else {
          this.$router.go(0);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.group-select-window {
  position: absolute;
  top: 100%;
  right: 0;
  width: 200px;
  padding: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.group-switcher-wrapper {
  border: none;
  background: $bg-header-dark;
}

/deep/ .vue-treeselect__list, /deep/ .vue-treeselect__menu {
  border: none;
  border-radius: 0;
  background: $bg-header-dark;
}

/deep/ .vue-treeselect--single .vue-treeselect__option--selected {
  color: $webmag-grey;
  background: $bg-header-dark;
}

/deep/ .vue-treeselect__option--highlight,
/deep/ .vue-treeselect--single .vue-treeselect__option--selected:hover {
  background: $webmag-light-grey;
  color: $bg-header-dark;
}

/deep/ .vue-treeselect__menu-container {
  left: -40px;
  width: calc(100% + 56px);
  background: $bg-header-dark;

  .vue-treeselect__option--selected {
    display: none;
  }
}

/deep/ .vue-treeselect__control {
  background: transparent;
  border: none;
  width: auto;
  border-radius: 0;

  .vue-treeselect__single-value, .vue-treeselect__option--selected {
    color: $webmag-light-grey;
    background: #53575B;
  }
}

/deep/ .vue-treeselect--disabled {
  padding-right: 3px;
}

/deep/ .vue-treeselect--disabled .vue-treeselect__control-arrow-container {
  display: none;
}

</style>
